// AUTO-CONVERTED FROM SPROCKETS
// Original file: app/assets/javascripts/shared/lib/proficiency.js
import { I18n } from "util/translations";

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export class Proficiency {
  constructor(interface_setting) {
    this.interface_setting = interface_setting;
    this.level_map = {
      3: [1, 3, 5],
      5: [1, 2, 3, 4, 5],
    };
  }

  update_labels(labels) {
    return (this.interface_setting.skill_proficiency_labels = labels);
  }

  enabled() {
    return this.interface_setting.enable_skill_proficiency === true;
  }

  default_label_for_level(level, key, lang = null) {
    const i18n_key = `skill_proficiency.level_${level}.${key}`;
    const int_label = I18n.t(i18n_key, { locale: "en" });
    return I18n.t(i18n_key, { locale: lang, defaultValue: int_label });
  }

  label_for(level, lang, key) {
    if (!this.interface_setting.skill_proficiency_labels) {
      return "";
    }

    if (level === 0) {
      return "";
    }

    const labels = this.interface_setting.skill_proficiency_labels;
    if (!labels) {
      return "";
    }

    const labels_for_lang = labels[lang];
    if (!labels_for_lang) {
      return "";
    }

    const labels_for_level = labels_for_lang[level];
    if (!labels_for_level) {
      return "";
    }
    return labels_for_level[key] || "";
  }

  label_or_default_for(level, lang, field) {
    if (field == null) {
      field = "name";
    }
    return (
      this.label_for(level, lang, field) ||
      this.default_label_for_level(level, field, lang)
    );
  }

  levels() {
    if (!this.interface_setting.skill_proficiency_levels) {
      return [];
    }
    return this.level_map[this.interface_setting.skill_proficiency_levels];
  }

  current_level() {
    return this.interface_setting.skill_proficiency_levels ?? 3;
  }
}
