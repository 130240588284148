import { useFlag } from '@unleash/proxy-client-react';

export function useDomain() {
  const rebranding_active = useFlag('rebranding');

  return rebranding_active ? 'flowcase.com' : 'cvpartner.com';
}

export function useEmailAddress(recipient: string) {
  const domain = useDomain();

  return `${recipient}@${domain}`;
}
