import { observer } from 'mobx-react';
import type React from 'react';
import type { LocString } from 'schema/schema';
import type { Sensitivity } from 'schema/sensitivity';
import { useSensitiveFieldsEnabled } from 'util/hooks/useSensitiveFieldsEnabled';
import * as styles from './SensitiveControl.css';
import { SensitivivityDropdown } from './SensitivityDropdown';

interface SensitiveControlProps {
  anonymizedHash?: LocString;
  anonymizedMenuItem?: 'none' | 'no-input' | 'input' | 'textarea';
  children?: React.ReactElement | null;
  displaySensitivityModal: boolean;
  languageCode: string;
  onClick: () => void;
  saveCallback: (value: {
    sensitivity: Sensitivity;
    anonymized?: LocString;
  }) => void;
  sensitiveControlText?: string | null;
  sensitiveControlType?: 'default' | 'no-border';
  sensitivity: Sensitivity;
}

const SensitiveControlComponent: React.FC<SensitiveControlProps> = ({
  anonymizedHash,
  anonymizedMenuItem = 'input',
  children,
  displaySensitivityModal,
  languageCode,
  onClick,
  saveCallback,
  sensitiveControlText,
  sensitiveControlType = 'default',
  sensitivity,
}) => {
  return (
    <div className={styles.sensitiveControl}>
      {displaySensitivityModal && anonymizedMenuItem === 'textarea' && (
        <SensitivivityDropdown
          anonymizedHash={anonymizedHash || {}}
          anonymizedMenuItem={anonymizedMenuItem}
          cancel={onClick}
          languageCode={languageCode}
          saveCallback={saveCallback}
          sensitivity={sensitivity}
        />
      )}

      <div className={styles.sensitiveAndRealValue}>
        <div
          className={styles.sensitiveIconWrapper({
            hasChildren: children ? true : false,
            hasBorder: sensitiveControlType !== 'no-border',
          })}
          onClick={onClick}
        >
          <div
            className={styles.icon({
              sensitivity: sensitivity ?? 'real',
              highlight:
                displaySensitivityModal && sensitiveControlType === 'no-border',
              position: anonymizedMenuItem === 'textarea' ? 'top' : 'center',
            })}
          />
          {anonymizedMenuItem !== 'textarea' &&
            sensitiveControlText != null && (
              <span className={styles.sensitiveValue}>
                {sensitiveControlText}
              </span>
            )}
        </div>
        {anonymizedMenuItem === 'textarea' && sensitiveControlText != null ? (
          <div className={styles.childrenWrapper}>
            <div className={styles.sensitiveValueTextarea}>
              {sensitiveControlText.split('\n').map((line, index) => (
                <span className={styles.sensitiveText} key={index}>
                  {line}
                </span>
              ))}
            </div>
            {children}
          </div>
        ) : (
          children
        )}
      </div>
      {displaySensitivityModal && anonymizedMenuItem !== 'textarea' && (
        <SensitivivityDropdown
          anonymizedHash={anonymizedHash || {}}
          anonymizedMenuItem={anonymizedMenuItem}
          cancel={onClick}
          languageCode={languageCode}
          saveCallback={saveCallback}
          sensitivity={sensitivity}
        />
      )}
    </div>
  );
};

const SensitiveControlWrapper = (props: SensitiveControlProps) => {
  const sensitiveFieldsEnabled = useSensitiveFieldsEnabled();
  if (!sensitiveFieldsEnabled) {
    return props.children || null;
  }
  return <SensitiveControlComponent {...props} />;
};

export const SensitiveControl = observer(SensitiveControlWrapper);
