import { useFlag } from '@unleash/proxy-client-react';
import { InterfaceSetting } from 'stores/InterfaceSetting';

export const useSensitiveFieldsEnabled = () => {
  return (
    (useFlag('CVP-sensitive-fields') &&
      InterfaceSetting.interface_setting.sensitive_fields_enabled) ??
    false
  );
};
