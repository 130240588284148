import { observer } from 'mobx-react';
import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import type { LocString } from 'schema/schema';
import type { Sensitivity } from 'schema/sensitivity';
import { I18n } from 'util/translations';
import * as styles from './SensitivityDropdown.css';
import { SensitivityDropdownMenuItem } from './SensitivityDropdownMenuItem';

interface SensitivivityDropdownProps {
  anonymizedHash: LocString;
  anonymizedMenuItem: 'none' | 'no-input' | 'input' | 'textarea';
  cancel: () => void;
  languageCode: string;
  saveCallback: (value: {
    sensitivity: Sensitivity;
    anonymized?: LocString;
  }) => void;
  sensitivity: Sensitivity;
}

const SensitivivityDropdownComponent: React.FC<SensitivivityDropdownProps> = ({
  anonymizedHash,
  anonymizedMenuItem = 'input',
  cancel,
  languageCode,
  saveCallback,
  sensitivity,
}) => {
  const [anonymizedValue, setAnonymizedValue] = useState(
    anonymizedHash?.[languageCode] || '',
  );
  const [sensitivityValue, setSensitivityValue] = useState(sensitivity);
  const editRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (editRef.current && !editRef.current.contains(event.target as Node)) {
        event.stopPropagation();
        cancel();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [cancel]);

  const onAnonymizedInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setAnonymizedValue(e.target.value);
  };

  // saveCallback({ sensitivity: 'xxx' }) to blank out the anonymized value of sensitive field along with changes to its sensitivity
  // saveCallback({ sensitivity: 'xxx', anonymized: {...} }) to make changes to anonymized value of sensitive field along with changes to its sensitivity
  const onSaveClick = () => {
    saveCallback({
      sensitivity: sensitivityValue,
      ...(sensitivityValue === 'anonymized' &&
      (anonymizedMenuItem === 'input' || anonymizedMenuItem === 'textarea')
        ? { anonymized: { [languageCode]: anonymizedValue } }
        : {}),
    });
  };

  function onInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSaveClick();
    }
  }

  return (
    <div className={styles.wrapper} ref={editRef}>
      <div
        className={styles.outerDiv({
          position: anonymizedMenuItem === 'textarea' ? 'top' : 'bottom',
        })}
      >
        <SensitivityDropdownMenuItem
          checked={sensitivityValue === null || sensitivityValue === 'real'}
          onChange={setSensitivityValue}
          sensitivity={null}
        />
        <SensitivityDropdownMenuItem
          checked={sensitivityValue === 'confidential'}
          onChange={setSensitivityValue}
          sensitivity="confidential"
        />
        {anonymizedMenuItem !== 'none' && (
          <SensitivityDropdownMenuItem
            checked={sensitivityValue === 'anonymized'}
            onChange={setSensitivityValue}
            sensitivity="anonymized"
            {...(anonymizedMenuItem === 'no-input'
              ? {
                  sensitivityDescriptionLabel:
                    'anonymized_sensitivity_description_without_input',
                }
              : {})}
          >
            {anonymizedMenuItem === 'input' &&
              sensitivityValue === 'anonymized' && (
                <div className={styles.anonymizedValueDiv}>
                  <input
                    className={styles.anonymizedValueInput}
                    id="anonymized-value"
                    onChange={onAnonymizedInputChange}
                    onKeyDown={onInputKeyDown}
                    type="text"
                    value={anonymizedValue}
                  />
                </div>
              )}
            {anonymizedMenuItem === 'textarea' &&
              sensitivityValue === 'anonymized' && (
                <div className={styles.anonymizedValueDiv}>
                  <textarea
                    id="anonymized-value"
                    onChange={onAnonymizedInputChange}
                    value={anonymizedValue}
                  />
                </div>
              )}
          </SensitivityDropdownMenuItem>
        )}

        <div className={styles.buttonsWrapper}>
          <div className={styles.buttonsDiv}>
            <a className="button" onClick={cancel}>
              {I18n.t('cancel')}
            </a>
            <button className="button blue" onClick={onSaveClick}>
              {I18n.t('save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SensitivivityDropdown = observer(SensitivivityDropdownComponent);
