import type React from 'react';
import type { Sensitivity } from 'schema/sensitivity';
import { I18n } from 'util/translations';
import { Checkmark } from '../Checkmark';
import * as styles from './SensitivityDropdownMenuItem.css';

interface SensitivityDropdownMenuItemProps {
  checked: boolean;
  children?: React.ReactNode;
  onChange: (sensitivity: Sensitivity) => void;
  sensitivity: Sensitivity;
  sensitivityDescriptionLabel?: string;
}

export const SensitivityDropdownMenuItem: React.FC<
  SensitivityDropdownMenuItemProps
> = ({
  checked,
  children,
  onChange,
  sensitivity,
  sensitivityDescriptionLabel,
}) => {
  const onSelected = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onChange(sensitivity);
  };

  const actualSensitivity = sensitivity ?? 'real';
  const sensitivityDescription = sensitivityDescriptionLabel
    ? I18n.t(sensitivityDescriptionLabel)
    : I18n.t(`${actualSensitivity}_sensitivity_description`);

  return (
    <div className={styles.dropdownMenuItem}>
      <div className={styles.radioButtonRowHighlight({ checked })}>
        <div className={styles.table} onClick={onSelected}>
          <div className={styles.row}>
            <div className={styles.iconCell}>
              <div
                className={styles.icon({
                  sensitivity: actualSensitivity,
                })}
              />
            </div>
            <div className={styles.labelCell}>
              <label htmlFor={actualSensitivity} className={styles.label}>
                {I18n.t(`${actualSensitivity}_sensitivity_label`)}
              </label>
            </div>

            <div className={styles.radioCell}>{checked && <Checkmark />}</div>
          </div>
          {sensitivityDescription && (
            <div className={styles.row}>
              <div className={styles.iconCell} />
              <div className={styles.labelCell}>
                <p className={styles.sensitivityDescription}>
                  {sensitivityDescription}
                </p>
              </div>
              <div className={styles.radioCell} />
            </div>
          )}
          {children && (
            <div className={styles.row}>
              <div className={styles.iconCell} />
              <div className={styles.labelCell}>{children}</div>
              <div className={styles.radioCell} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
