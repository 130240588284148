import React from 'react';
import { Group, type GroupProps } from 'react-aria-components';
import type { OmittedReactAriaProps } from '../types';
import { base } from './Checkmark.css';

export type Props = Omit<GroupProps, OmittedReactAriaProps>;

const BaseCheckmark: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  props,
  ref,
) => {
  return (
    <Group ref={ref} {...props} className={base}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.6667 5L7.50001 14.1667L3.33334 10"
          stroke="#264EFF"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Group>
  );
};

const ReffedCheckmark = React.forwardRef(BaseCheckmark);

export const Checkmark = React.memo(ReffedCheckmark);
