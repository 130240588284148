import classNames from 'clsx';
import type React from 'react';
import { I18n } from 'util/translations';

const Container: React.FC<{
  defaultClassName: string;
  savingFlag: boolean;
}> = ({ defaultClassName, savingFlag }) => (
  <div
    className={classNames(defaultClassName, {
      activated: savingFlag,
    })}
  >
    <div className="upper_state" title={I18n.t('autosaved')} />
  </div>
);

interface SavingIndicatorProps {
  primarySaving?: boolean;
  secondarySaving?: boolean;
}

export default function SavingIndicator({
  primarySaving = false,
  secondarySaving = false,
}: SavingIndicatorProps) {
  return (
    <div className="saving_state_wrapper">
      <Container
        defaultClassName="local_state_container"
        savingFlag={primarySaving}
      />
      <Container
        defaultClassName="int_state_container"
        savingFlag={secondarySaving}
      />
    </div>
  );
}
