// AUTO-CONVERTED FROM SPROCKETS
// Original file: app/assets/javascripts/shared/lib/sections_hash.js
import _ from "underscore";

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export class SectionsHash {
  constructor(interface_setting, preview) {
    if (preview == null) {
      preview = false;
    }
    this.interface_setting = interface_setting;
    this.preview = preview;
  }

  key_qualifications_subsections() {
    if (
      !this.preview &&
      !this.interface_setting.show_key_qualifications_key_points
    ) {
      return false;
    }
    return [
      {
        name: "key_points",
        disabled: !this.interface_setting.show_key_qualifications_key_points,
        toggle_key: "show_key_qualifications_key_points",
        param_wrapper_key: "key_point",
        expanded_fields: {
          text_fields: [
            {
              name: "name",
              multilang: true,
              input_field_type: "text",
              label_key: "Key_Point_Text",
              read_only_class: "subsection_name",
            },
          ],
          upper_textarea_fields:
            this.key_qualifications_key_points_upper_textarea_fields(),
        },
      },
    ];
  }

  key_qualifications_key_points_upper_textarea_fields() {
    if (
      !this.preview &&
      !this.interface_setting
        .show_key_qualifications_key_points_long_description
    ) {
      return false;
    }
    return [
      {
        name: "long_description",
        multilang: true,
        input_field_type: "textarea",
        disabled:
          !this.interface_setting
            .show_key_qualifications_key_points_long_description,
        toggle_key: "show_key_qualifications_key_points_long_description",
        label_key: "Description",
        read_only_class: "subsection_description",
      },
    ];
  }

  key_qualifications_lower_textarea_fields() {
    if (
      !this.preview &&
      !this.interface_setting.show_key_qualifications_tag_line
    ) {
      return false;
    }
    return [
      {
        name: "tag_line",
        multilang: true,
        input_field_type: "textarea",
        disabled: !this.interface_setting.show_key_qualifications_tag_line,
        toggle_key: "show_key_qualifications_tag_line",
        recommended_length_key:
          "key_qualifications_tag_line_recommended_length",
        recommended_length:
          this.interface_setting.key_qualifications_tag_line_recommended_length,
      },
    ];
  }

  sections_hash() {
    const hash = {
      personal_details: {
        param_wrapper_key: "personal_details",
        label_key: "personal_details",
        tab_index_offset: 50,
        expanded_fields: {
          text_fields: [
            {
              name: "name_multilang",
              toggle_key: "show_personal_details_name_multilang",
              disabled:
                !this.interface_setting.show_personal_details_name_multilang,
            },
            {
              name: "landline",
              toggle_key: "show_personal_details_landline",
              disabled: !this.interface_setting.show_personal_details_landline,
            },
            {
              name: "place_of_residence",
              toggle_key: "show_personal_details_place_of_residence",
              disabled: !(
                this.interface_setting
                  .show_personal_details_place_of_residence === undefined ||
                this.interface_setting.show_personal_details_place_of_residence
              ),
            },
            {
              name: "twitter",
              toggle_key: "show_personal_details_twitter",
              disabled: !this.interface_setting.show_personal_details_twitter,
            },
            {
              name: "nationality",
              toggle_key: "show_personal_details_nationality",
              disabled: !(
                this.interface_setting.show_personal_details_nationality ===
                  undefined ||
                this.interface_setting.show_personal_details_nationality
              ),
            },
            {
              name: "born_year",
              toggle_key: "show_personal_details_born_year",
              disabled: !(
                this.interface_setting.show_personal_details_born_year ===
                  undefined ||
                this.interface_setting.show_personal_details_born_year
              ),
            },
          ],
        },
      },
      key_qualifications: {
        param_wrapper_key: "key_qualification",
        label_key: "key_qualification",
        tab_index_offset: 200,
        radio_button: true,
        expanded_fields: {
          text_fields: [
            {
              name: "label",
              multilang: true,
              extra_input_classnames: "extra_min_width",
              input_field_type: "text",
              placeholder: "key_qualifications_summary_placeholder",
            },
          ],
          upper_textarea_fields: [
            {
              name: "long_description",
              multilang: true,
              input_field_type: "textarea",
              recommended_length_key:
                "key_qualifications_long_description_recommended_length",
              recommended_length:
                this.interface_setting
                  .key_qualifications_long_description_recommended_length,
            },
          ],
          subsections: this.key_qualifications_subsections(),
          lower_textarea_fields:
            this.key_qualifications_lower_textarea_fields(),
        },
        collapsed_fields: {
          text_fields: [
            { field_name: "label", emphasize: true },
            { field_name: "long_description" },
          ],
          subsections: [
            {
              subsection_name: "key_points",
              subsection: true,
              field_name: "name",
            },
          ],
        },
      },
      project_experiences: {
        param_wrapper_key: "project_experience",
        expanded_fields: {
          date_fields: [
            { name: "month_from" },
            { name: "year_from" },
            { name: "month_to" },
            { name: "year_to" },
          ],
          text_fields: [
            {
              name: "customer",
              multilang: true,
            },
            {
              name: "customer_anonymized",
              multilang: true,
            },
            {
              name: "customer_anonymized",
              multilang: true,
              input_field_type: "text",
            },
            {
              name: "description",
              multilang: true,
            },
            {
              name: "industry",
              multilang: true,
            },
            {
              name: "project_type",
              multilang: true,
            },
            {
              name: "contract_type",
              multilang: true,
            },
          ],
          upper_textarea_fields: [
            { name: "customer_description" },
            { name: "long_description" },
            { name: "customer_value_proposition" },
          ],
          subsections: [
            {
              name: "roles",
              expanded_fields: {
                text_fields: [{ name: "name" }],
                upper_textarea_fields: [
                  { name: "long_description" },
                  { name: "summary" },
                ],
              },
            },
          ],
          skills: {
            name: "project_experience_skills",
          },
        },
      },
      cv_roles: {
        label_key: "cv_roles",
        tab_index_offset: 400,
        convertible: false,
        expanded_fields: {
          text_fields: [
            { name: "name", multilang: true, input_field_type: "text" },
          ],
        },
      },
      technologies: {
        param_wrapper_key: "technology",
        label_key: "technology",
        tab_index_offset: 400,
        expanded_fields: {
          text_fields: [
            { name: "category", multilang: true, input_field_type: "text" },
            {
              name: "add_skill_outside_project_experience",
              toggle_key: "show_add_skill_outside_project_experience",
              disabled: !(
                this.interface_setting
                  .show_add_skill_outside_project_experience === undefined ||
                this.interface_setting.show_add_skill_outside_project_experience
              ),
            },
          ],
        },
      },
      educations: {
        param_wrapper_key: "education",
        label_key: "education",
        tab_index_offset: 500,
        expanded_fields: {
          date_fields: [
            { name: "month_from", input_field_type: "month" },
            { name: "year_from", input_field_type: "year" },
            { name: "month_to", input_field_type: "month" },
            { name: "year_to", input_field_type: "year" },
          ],
          text_fields: [
            {
              name: "school",
              multilang: true,
              input_field_type: "text",
              read_only_class: "primary_field",
              autosuggest: {
                load_on_empty: true,
              },
              autotranslate: {},
            },
            {
              name: "degree",
              multilang: true,
              input_field_type: "text",
              read_only_class: "secondary_field",
              autosuggest: {
                load_on_empty: true,
              },
              autotranslate: {},
            },
          ],
          lower_textarea_fields: [
            {
              name: "description",
              multilang: true,
              input_field_type: "textarea",
            },
          ],
        },
        collapsed_fields: {
          date_fields: ["month_from", "month_to", "year_from", "year_to"],
          text_fields: [
            { field_name: "school" },
            { field_name: "degree", emphasize: true },
          ],
        },
      },
    };
    if (this.preview || this.interface_setting.show_work_experiences) {
      hash["work_experiences"] = this.work_experiences();
    }
    if (this.preview || this.interface_setting.show_certifications) {
      hash["certifications"] = this.certifications();
    }
    if (this.preview || this.interface_setting.show_courses) {
      hash["courses"] = this.courses();
    }
    if (this.preview || this.interface_setting.show_presentations) {
      hash["presentations"] = this.presentations();
    }
    if (this.preview || this.interface_setting.show_recommendations) {
      hash["recommendations"] = this.recommendations();
    }
    if (this.preview || this.interface_setting.show_positions) {
      hash["positions"] = this.positions();
    }
    if (this.preview || this.interface_setting.show_mentorings) {
      hash["mentorings"] = this.mentorings();
    }
    if (this.preview || this.interface_setting.show_blogs) {
      hash["blogs"] = this.blogs();
    }
    if (this.preview || this.interface_setting.show_honors_awards) {
      hash["honors_awards"] = this.honors_awards();
    }
    hash["languages"] = this.languages();
    return hash;
  }

  work_experiences() {
    return {
      param_wrapper_key: "work_experience",
      label_key: "work_experience",
      tab_index_offset: 600,
      toggle_key: "show_work_experiences",
      disabled: !this.interface_setting.show_work_experiences,
      expanded_fields: {
        date_fields: [
          { name: "month_from", input_field_type: "month" },
          { name: "year_from", input_field_type: "year" },
          { name: "month_to", input_field_type: "month" },
          { name: "year_to", input_field_type: "year" },
        ],
        text_fields: [
          {
            name: "employer",
            multilang: true,
            input_field_type: "text",
            read_only_class: "primary_field",
          },
          {
            name: "description",
            multilang: true,
            input_field_type: "text",
            read_only_class: "secondary_field",
          },
        ],
        lower_textarea_fields: [
          {
            name: "long_description",
            multilang: true,
            input_field_type: "textarea",
          },
        ],
      },
      collapsed_fields: {
        date_fields: ["month_from", "month_to", "year_from", "year_to"],
        text_fields: [
          { field_name: "employer", emphasize: true },
          { field_name: "description" },
        ],
      },
    };
  }

  certifications() {
    return {
      param_wrapper_key: "certification",
      label_key: "certification",
      tab_index_offset: 700,
      toggle_key: "show_certifications",
      disabled: !this.interface_setting.show_certifications,
      expanded_fields: {
        date_fields: [
          { name: "month", input_field_type: "month" },
          { name: "year", input_field_type: "year" },
          { name: "month_expire", input_field_type: "month" },
          { name: "year_expire", input_field_type: "year" },
        ],
        text_fields: [
          {
            name: "name",
            multilang: true,
            input_field_type: "text",
            read_only_class: "secondary_field",
            autosuggest: {
              load_on_empty: true,
            },
          },
          {
            name: "organiser",
            multilang: true,
            input_field_type: "text",
            read_only_class: "meta_field",
            autosuggest: {
              load_on_empty: true,
            },
          },
        ],
        lower_textarea_fields: [
          {
            name: "long_description",
            multilang: true,
            input_field_type: "textarea",
          },
        ],
      },
      collapsed_fields: {
        date_fields: ["month", "year"],
        date_flag_fields: {
          year: "year_expire",
          month: "month_expire",
          label_key: "expired",
        },
        text_fields: [
          { field_name: "name", emphasize: true },
          { field_name: "organiser" },
        ],
      },
    };
  }

  courses() {
    return {
      param_wrapper_key: "course",
      label_key: "course",
      tab_index_offset: 800,
      toggle_key: "show_courses",
      disabled: !this.interface_setting.show_courses,
      expanded_fields: {
        date_fields: [
          { name: "month", input_field_type: "month" },
          { name: "year", input_field_type: "year" },
        ],
        text_fields: [
          {
            name: "name",
            multilang: true,
            input_field_type: "text",
            read_only_class: "secondary_field",
            autosuggest: {
              load_on_empty: true,
            },
            autotranslate: {},
          },
          {
            name: "program",
            multilang: true,
            input_field_type: "text",
            read_only_class: "meta_field",
            autosuggest: {
              load_on_empty: true,
            },
            autotranslate: {},
          },
        ],
        lower_textarea_fields: [
          {
            name: "long_description",
            multilang: true,
            input_field_type: "textarea",
          },
        ],
      },
      collapsed_fields: {
        date_fields: ["year"],
        text_fields: [
          { field_name: "name", emphasize: true },
          { field_name: "program" },
        ],
      },
    };
  }

  presentations() {
    return {
      param_wrapper_key: "presentation",
      label_key: "presentation",
      tab_index_offset: 900,
      toggle_key: "show_presentations",
      disabled: !this.interface_setting.show_presentations,
      expanded_fields: {
        date_fields: [
          { name: "month", input_field_type: "month" },
          { name: "year", input_field_type: "year" },
        ],
        text_fields: [
          {
            name: "description",
            multilang: true,
            input_field_type: "text",
            read_only_class: "secondary_field",
          },
        ],
        lower_textarea_fields: [
          {
            name: "long_description",
            multilang: true,
            input_field_type: "textarea",
          },
        ],
      },
      collapsed_fields: {
        date_fields: ["month", "year"],
        text_fields: [
          { field_name: "description", emphasize: true },
          { field_name: "long_description" },
        ],
      },
    };
  }

  recommendations() {
    return {
      param_wrapper_key: "recommendation",
      label_key: "recommendation",
      tab_index_offset: 1000,
      toggle_key: "show_recommendations",
      disabled: !this.interface_setting.show_recommendations,
      expanded_fields: {
        text_fields: [
          {
            name: "recommender",
            multilang: true,
            input_field_type: "text",
            read_only_class: "secondary_field",
          },
        ],
        lower_textarea_fields: [
          {
            name: "long_description",
            multilang: true,
            input_field_type: "textarea",
          },
        ],
      },
      collapsed_fields: {
        text_fields: [
          { field_name: "recommender", emphasize: true },
          { field_name: "long_description" },
        ],
      },
    };
  }

  positions() {
    return {
      param_wrapper_key: "position",
      label_key: "position",
      tab_index_offset: 1100,
      toggle_key: "show_positions",
      disabled: !this.interface_setting.show_positions,
      expanded_fields: {
        date_fields: [
          { name: "year_from", input_field_type: "year" },
          { name: "year_to", input_field_type: "year" },
        ],
        text_fields: [
          {
            name: "name",
            multilang: true,
            input_field_type: "text",
            read_only_class: "secondary_field",
          },
        ],
        lower_textarea_fields: [
          {
            name: "description",
            multilang: true,
            input_field_type: "textarea",
          },
        ],
      },
      collapsed_fields: {
        date_fields: ["year_from", "year_to"],
        text_fields: [
          { field_name: "name", emphasize: true },
          { field_name: "description" },
        ],
      },
    };
  }

  mentorings() {
    return {
      param_wrapper_key: "mentoring",
      label_key: "mentorings",
      tab_index_offset: 1200,
      toggle_key: "show_mentorings",
      disabled: !this.interface_setting.show_mentorings,
      expanded_fields: {
        date_fields: [
          { name: "month_from", input_field_type: "month" },
          { name: "year_from", input_field_type: "year" },
          { name: "month_to", input_field_type: "month" },
          { name: "year_to", input_field_type: "year" },
        ],
        text_fields: [
          {
            name: "context",
            multilang: true,
            input_field_type: "text",
            read_only_class: "primary_field",
          },
        ],
        lower_textarea_fields: [
          {
            name: "long_description",
            multilang: true,
            input_field_type: "textarea",
          },
        ],
      },
      collapsed_fields: {
        date_fields: ["month_from", "month_to", "year_from", "year_to"],
        text_fields: [
          { field_name: "context", emphasize: true },
          { field_name: "long_description" },
        ],
      },
    };
  }

  blogs() {
    return {
      param_wrapper_key: "blog",
      label_key: "blog",
      tab_index_offset: 1300,
      toggle_key: "show_blogs",
      disabled: !this.interface_setting.show_blogs,
      expanded_fields: {
        date_fields: [
          { name: "month", input_field_type: "month" },
          { name: "year", input_field_type: "year" },
        ],
        text_fields: [
          {
            name: "name",
            multilang: true,
            input_field_type: "text",
            read_only_class: "secondary_field",
          },
          {
            name: "url",
            input_field_type: "text",
            read_only_class: "meta_field",
          },
        ],
        lower_textarea_fields: [
          {
            name: "long_description",
            multilang: true,
            input_field_type: "textarea",
          },
        ],
      },
      collapsed_fields: {
        date_fields: ["month", "year"],
        text_fields: [
          { field_name: "name", emphasize: true },
          { field_name: "url" },
        ],
      },
    };
  }

  honors_awards() {
    return {
      param_wrapper_key: "honors_award",
      label_key: "honors_awards",
      tab_index_offset: 1400,
      toggle_key: "show_honors_awards",
      disabled: !this.interface_setting.show_honors_awards,
      expanded_fields: {
        date_fields: [
          { name: "month", input_field_type: "month" },
          { name: "year", input_field_type: "year" },
        ],
        text_fields: [
          {
            name: "name",
            multilang: true,
            input_field_type: "text",
            read_only_class: "secondary_field",
          },
          {
            name: "for_work",
            multilang: true,
            input_field_type: "text",
            read_only_class: "meta_field",
          },
          {
            name: "issuer",
            multilang: true,
            input_field_type: "text",
            read_only_class: "meta_field",
          },
        ],
        lower_textarea_fields: [
          {
            name: "long_description",
            multilang: true,
            input_field_type: "textarea",
          },
        ],
      },
      collapsed_fields: {
        date_fields: ["month", "year"],
        text_fields: [
          { field_name: "name", emphasize: true },
          { field_name: "for_work" },
          { field_name: "issuer" },
        ],
      },
    };
  }

  languages() {
    return {
      param_wrapper_key: "language",
      label_key: "language",
      tab_index_offset: 1500,
      expanded_fields: {
        text_fields: [
          {
            name: "name",
            multilang: true,
            input_field_type: "text",
            read_only_class: "secondary_field",
            autosuggest: {
              load_on_empty: true,
            },
            autotranslate: {},
          },
          {
            name: "level",
            multilang: true,
            input_field_type: "text",
            read_only_class: "meta_field",
            autosuggest: {
              load_on_empty: true,
            },
            autotranslate: {},
          },
        ],
      },
      collapsed_fields: {
        text_fields: [
          { field_name: "name", emphasize: true },
          { field_name: "level" },
        ],
      },
    };
  }

  section_keys() {
    return _.keys(this.sections_hash());
  }
}
