import classNames from 'clsx';
import {
  type CSSProperties,
  type ChangeEvent,
  type JSX,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import SavingIndicator from 'components/shared/SavingIndicator';
import SavingLabel from 'components/shared/SavingLabel';
import type { LocString } from 'schema/schema';
import type { EnabledSensitiveFields, Sensitivity } from 'schema/sensitivity';
import { I18n } from 'util/translations';
import { SensitiveFieldControl } from './sensitivity/SensitiveFieldControl';

interface DropdownProps {
  field?: string;
  onChange: (value: string) => void;
  options: {
    value: string;
    name: string;
    selectedName?: string;
    optgroup?: string;
    disabled?: boolean;
  }[];
  nonAlphabeticalSort?: boolean;
  label?: string;
  tabIndex?: number;
  value?: string;
  extraClasses?: string;
  extraWrapperClasses?: string;
  maxWidth?: number;
  invalid?: boolean;
  dangerouslyUseHTML?: boolean;
  anonymizedHash?: LocString;
  anonymizedFieldName?: EnabledSensitiveFields;
  isSensitive?: boolean;
  isProposal?: boolean;
  languageCode?: string;
  onSensitiveChange?: (
    value: Record<string, string | LocString | null>,
  ) => void;
  sensitivity?: Sensitivity;
}

export default function Dropdown({
  field,
  label,
  onChange,
  options,
  nonAlphabeticalSort,
  tabIndex,
  value,
  extraWrapperClasses,
  extraClasses,
  maxWidth,
  invalid,
  dangerouslyUseHTML,
  anonymizedHash,
  anonymizedFieldName,
  isSensitive,
  isProposal,
  languageCode,
  onSensitiveChange,
  sensitivity,
}: DropdownProps) {
  const [optionElements, setOptionElements] = useState<JSX.Element[]>([]);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [dropdownWidth, setDropdownWidth] = useState(0);
  const theGhost = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedValue((selectedValue) =>
      selectedValue && selectedValue !== value ? selectedValue : null,
    );
  }, [value]);

  useEffect(() => {
    recalculateSize();
  });

  useEffect(() => {
    const sortGroup = (group) => {
      // Sorts the way the options are listed - Can extend this with custom sort functions if needed
      if (nonAlphabeticalSort) {
        return group;
      }
      return group.sort((a, b) => a.name.localeCompare(b.name));
    };

    const groupedOptions = options.reduce((acc, option) => {
      if (option.optgroup) {
        acc[option.optgroup] = acc[option.optgroup] || [];
        acc[option.optgroup].push(option);
        return acc;
      }
      acc['nooptgroup'] = acc['nooptgroup'] || [];
      acc['nooptgroup'].push(option);
      return acc;
    }, Object.create(null));

    setOptionElements(
      Object.keys(groupedOptions).map((key) => {
        const group = groupedOptions[key];
        if (key === 'nooptgroup') {
          return sortGroup(group).map(({ value, name, disabled }) => (
            <option
              key={value}
              value={value}
              disabled={disabled}
              {...(dangerouslyUseHTML
                ? { dangerouslySetInnerHTML: { __html: name } }
                : { children: name })}
            ></option>
          ));
        }
        return (
          <optgroup key={key} label={I18n.t(key)}>
            {sortGroup(group).map(({ value, name, disabled }) => (
              <option
                key={value}
                value={value}
                disabled={disabled}
                {...(dangerouslyUseHTML
                  ? { dangerouslySetInnerHTML: { __html: name } }
                  : { children: name })}
              ></option>
            ))}
          </optgroup>
        );
      }),
    );
  }, [dangerouslyUseHTML, nonAlphabeticalSort, options]);

  const isSaving = () => {
    return selectedValue ? value !== selectedValue : false;
  };

  const onChangeOption = (event: ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const hasSelectedName = useMemo(() => {
    return options.some((x) => x.selectedName);
  }, [options]);

  const displayValue = useMemo(() => {
    if (selectedValue) {
      const val = options.find((x) => x.value === selectedValue);

      return val?.selectedName ?? val?.name;
    }
    const val = options.find((x) => x.value === value);
    return val?.selectedName ?? val?.name;
  }, [options, selectedValue, value]);

  const recalculateSize = () => {
    const ghost = theGhost.current;
    if (!ghost) {
      return;
    }
    let newDropdownWidth = ghost.scrollWidth + 2;
    if (maxWidth && newDropdownWidth > maxWidth) {
      newDropdownWidth = maxWidth;
    }
    if (newDropdownWidth !== dropdownWidth) {
      setDropdownWidth(newDropdownWidth);
    }
  };

  const ghostStyle: CSSProperties = {
    top: 0,
    left: 0,
    height: 0,
    minHeight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    borderTop: 0,
    borderBottom: 0,
    whiteSpace: 'nowrap',
    position: 'absolute',
    overflow: 'hidden',
  };

  const showValue =
    displayValue ||
    (field && I18n.t(`select_${field}`)) ||
    options[0]?.selectedName ||
    options[0]?.name;

  return (
    <>
      {label && (
        <label className={classNames({ red_label: invalid })}>
          {I18n.t(label)}
        </label>
      )}
      <div
        className={classNames(
          'dropdown_container_container',
          sensitivity && 'sensitive',
          isProposal && 'proposal',
          extraWrapperClasses,
        )}
      >
        <SavingLabel show={isSaving()} saving={isSaving()} />
        <SavingIndicator primarySaving={isSaving()} />
        <SensitiveFieldControl
          anonymizedHash={anonymizedHash || {}}
          fieldName={anonymizedFieldName || null}
          isSensitive={isSensitive || false}
          isProposal={isProposal || false}
          languageCode={languageCode || ''}
          realValue={showValue}
          saveCallback={onSensitiveChange}
          sensitivity={sensitivity || null}
        >
          <>
            <select
              value={selectedValue || value || ''}
              onChange={onChangeOption}
              className={classNames('dropdown_container', extraClasses)}
              tabIndex={tabIndex}
              style={{ width: dropdownWidth }}
            >
              {field && <option value="">{I18n.t(`select_${field}`)}</option>}
              {optionElements}
            </select>
            {hasSelectedName && (
              <div
                className={classNames('dropdown_container', extraClasses)}
                style={{
                  pointerEvents: 'none',
                  position: 'absolute',
                  left: '1px',
                  top: '0px',
                }}
              >
                {showValue}
              </div>
            )}
            <div
              className={classNames('dropdown_container', extraClasses)}
              style={ghostStyle}
              ref={theGhost}
              {...(dangerouslyUseHTML
                ? { dangerouslySetInnerHTML: { __html: showValue } }
                : { children: showValue })}
            />
          </>
        </SensitiveFieldControl>
      </div>
    </>
  );
}
