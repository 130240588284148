import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { Proficiency as LibProficiency } from 'auto-converted/lib/Proficiency';
import { SectionsHash as LibSectionsHash } from 'auto-converted/lib/SectionsHash';
import type { LocString } from 'schema/schema';
import { http } from 'util/fetch';

interface SkillProficiencyLabels {
  name?: string;
  description?: string;
}

// this list is not exhaustive, but it's a good start
export type Settings = {
  allow_external_proposal_sharing?: boolean;
  allow_proposal_specific_api_keys?: boolean;
  enable_skill_proficiency?: boolean;
  gpt_customer_descriptions_enabled?: boolean;
  international_enabled?: boolean;
  require_upn?: boolean;
  sensitive_fields_enabled?: boolean;
  show_add_skill_outside_project_experience?: boolean;
  show_certifications?: boolean;
  show_courses?: boolean;
  show_key_qualifications_key_points?: boolean;
  show_project_experiences_area?: boolean;
  show_project_experiences_customer_contacts?: boolean;
  show_project_experiences_customer_description?: boolean;
  show_project_experiences_customer_value_proposition?: boolean;
  show_project_experiences_extent_hours?: boolean;
  show_project_experiences_location_country_code?: boolean;
  show_project_experiences_percent_allocated?: boolean;
  show_project_experiences_project_extent?: boolean;
  show_project_experiences_project_extent_hours?: boolean;
  show_project_experiences_project_type?: boolean;
  show_project_experiences_related_work_experience_id?: boolean;
  show_project_experiences_roles_summary?: boolean;
  show_project_experiences_total_extent?: boolean;
  show_project_experiences_total_extent_hours?: boolean;
  show_project_experiences_location_delivery?: boolean;
  show_project_experiences_contract_type?: boolean;
  show_references_tab?: boolean;
  aws_translations_enabled_cvs?: boolean;
  aws_translations_enabled_rps?: boolean;
  aws_translations_enabled_masterdata?: boolean;
  aws_translations_version?: number;
  autotranslations_enabled?: boolean;
  genai_proofreading_enabled?: boolean;
  genai_reduction_enabled?: boolean;

  // string or number?
  project_experiences_long_description_recommended_length?: number;
  // string or number?
  project_experiences_roles_long_description_recommended_length?: number;

  skill_proficiency_labels?: Record<
    string,
    | {
        1?: SkillProficiencyLabels;
        2?: SkillProficiencyLabels;
        3?: SkillProficiencyLabels;
        4?: SkillProficiencyLabels;
        5?: SkillProficiencyLabels;
      }
    | undefined
  >;
  skill_proficiency_levels?: 3 | 5;

  external_unique_id_help_texts?: LocString;
  upn_help_texts?: LocString;

  show_blogs?: boolean;
  show_positions?: boolean;
  show_mentorings?: boolean;
  show_honors_awards?: boolean;
  show_presentations?: boolean;
  show_recommendations?: boolean;
  show_work_experiences?: boolean;
  show_reference_project_area?: boolean;
  show_reference_project_type?: boolean;
  show_reference_project_contract_type?: boolean;
  show_personal_details_twitter?: boolean;
  show_reference_project_extent?: boolean;
  show_personal_details_landline?: boolean;
  show_personal_details_born_year?: boolean;
  show_key_qualifications_tag_line?: boolean;
  show_reference_project_architect?: boolean;
  show_personal_details_nationality?: boolean;
  show_reference_project_extent_old?: boolean;
  show_reference_project_extent_hours?: boolean;
  show_reference_project_introduction?: boolean;
  show_reference_project_total_extent?: boolean;
  show_personal_details_name_multilang?: boolean;
  show_reference_project_building_owner?: boolean;
  show_reference_project_extent_hours_old?: boolean;
  show_reference_project_total_extent_hours?: boolean;
  show_reference_project_location_country_code?: boolean;
  show_reference_project_collaborating_partners?: boolean;
  key_qualifications_tag_line_recommended_length?: string;
  show_key_qualifications_key_points_long_description?: boolean;
  // string or number?
  key_qualifications_long_description_recommended_length?: string;
};

interface LastModified {
  id: string;
  name: string;
  updated_at: string;
}

type LastModifiedSettings = {
  [key in keyof Settings]: LastModified;
};

class InterfaceSettingClass {
  // TODO: use `null` instead of empty objects
  interface_setting: Settings = {};
  last_modified: LastModifiedSettings = {};
  has_fetched_data = false;
  private last_modified_set = false;

  constructor() {
    makeObservable<
      InterfaceSettingClass,
      'add_to_cache' | 'add_to_last_modified_cache' | 'last_modified_set'
    >(this, {
      interface_setting: observable,
      last_modified: observable,
      has_fetched_data: observable,
      last_modified_set: false,
      reload: false,
      reload_last_modified: false,
      update: false,
      sections_hash: computed,
      sections_hash_preview: computed,
      proficiency: computed,
      add_to_cache: action,
      add_to_last_modified_cache: action,
    });
  }

  async reload() {
    // the only remaining interface setting exposed externally
    const url = '/api/v1/interface_setting';
    const [interface_setting] = await Promise.all([
      http.get(url),
      this.last_modified_set ? this.reload_last_modified() : null,
    ]);
    this.add_to_cache(interface_setting as Settings);
  }

  async reload_last_modified() {
    this.last_modified_set = true;

    const url = '/interface_setting/last_modified';
    const modification_hash = await http.get(url);

    this.add_to_last_modified_cache(modification_hash as LastModifiedSettings);
  }

  async update(update_hash: Settings) {
    const url = '/interface_setting';
    const body = {
      interface_setting: update_hash,
    };
    const interface_setting = await http.put(url, { body });
    this.add_to_cache(interface_setting as Settings);
    if (this.last_modified_set) {
      await this.reload_last_modified();
    }
  }

  get sections_hash() {
    return new LibSectionsHash(toJS(this.interface_setting), false);
  }

  get sections_hash_preview() {
    return new LibSectionsHash(toJS(this.interface_setting), true);
  }

  get proficiency() {
    const interface_setting = toJS({
      enable_skill_proficiency: this.interface_setting.enable_skill_proficiency,
      skill_proficiency_levels: this.interface_setting.skill_proficiency_levels,
      skill_proficiency_labels: this.interface_setting.skill_proficiency_labels,
    });
    return new LibProficiency(interface_setting);
  }

  private add_to_cache(interface_setting: Settings) {
    this.has_fetched_data = true;
    this.interface_setting = interface_setting;
  }

  private add_to_last_modified_cache(last_modified: LastModifiedSettings) {
    this.last_modified = last_modified;
  }
}

export const InterfaceSetting = new InterfaceSettingClass();
