import classNames from 'clsx';
import { I18n } from 'util/translations';

interface SavingLabelProps {
  show: boolean;
  saving: boolean;
  international?: boolean;
}

export default function SavingLabel({
  show,
  saving,
  international = false,
}: SavingLabelProps) {
  return show ? (
    <div
      className={classNames('saved_message saved', {
        overlay_saved_message: international,
      })}
    >
      {I18n.t(saving ? 'saving' : 'saved')}
    </div>
  ) : null;
}
