import { LegacyButton as Button } from '@cvpartner/design-system';
import { File06, SearchLg } from '@untitled-ui/icons-react';
import { observer } from 'mobx-react';
import type * as React from 'react';
import { Link } from 'react-router-dom';
import { CurrentUser } from 'stores/CurrentUser';
import { I18n } from 'util/translations';
import {
  buttons,
  container,
  headingAndSupportingText,
  paragraph,
  wrapper,
} from './404.css';
import illustration from './404.svg';

const isDashboard = window.location.pathname === '/dashboard';

const LinkOrAnchor: React.VFC<{ to: string; children: React.ReactNode }> = ({
  to,
  children,
}) => {
  if (isDashboard) {
    return (
      <Link to={to} replace={true}>
        {children}
      </Link>
    );
  }

  return <a href={`/dashboard#${to}`}>{children}</a>;
};

const baseCvUrl = '/persons/proposal/false';

const LinksComponent: React.VFC = () => {
  const { default_cv_id, id } = CurrentUser;

  const cvUrl =
    default_cv_id && id ? `${baseCvUrl}/cv/${id}/${default_cv_id}` : baseCvUrl;

  return (
    <>
      <LinkOrAnchor to={cvUrl}>
        <Button variant="primary" icon={File06}>
          {I18n.t('My_CV')}
        </Button>
      </LinkOrAnchor>
      <LinkOrAnchor to="/dashboard">
        <Button variant="primary" icon={SearchLg}>
          {I18n.t('Dashboard')}
        </Button>
      </LinkOrAnchor>
    </>
  );
};

const Links = observer(LinksComponent);

export const NotFound: React.VFC<{ isLoggedIn: boolean }> = ({
  isLoggedIn,
}) => {
  return (
    <main className={wrapper}>
      <div className={container}>
        <div>
          <div className={headingAndSupportingText}>
            <h1>{I18n.t('under_maintenance')}</h1>
            <p className={paragraph}>{I18n.t('page_moved')}</p>
          </div>
          <div className={buttons}>
            {isLoggedIn ? (
              <Links />
            ) : (
              <a href="/login">
                <Button variant="primary">{I18n.t('Log_in')}</Button>
              </a>
            )}
          </div>
        </div>
        <div>
          <img src={illustration} />
        </div>
      </div>
    </main>
  );
};
