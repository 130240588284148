import { observer } from 'mobx-react';
import type React from 'react';
import { useState } from 'react';
import { HashUtil } from 'auto-converted/lib/HashUtil';
import type { LocString } from 'schema/schema';
import type { EnabledSensitiveFields, Sensitivity } from 'schema/sensitivity';
import { useSensitiveFieldsEnabled } from 'util/hooks/useSensitiveFieldsEnabled';
import { I18n } from 'util/translations';
import { SensitiveControl } from './SensitiveControl';

interface SensitiveFieldControlProps {
  anonymizedHash: LocString;
  anonymizedMenuItem?: 'none' | 'no-input' | 'input' | 'textarea';
  children: React.ReactElement;
  fieldName: EnabledSensitiveFields | null;
  isProposal: boolean;
  isSensitive: boolean;
  languageCode: string;
  realValue?: string;
  saveCallback?: (
    value: Record<string, Sensitivity | LocString | null>,
  ) => void;
  sensitivity: Sensitivity;
}

const SensitiveFieldControlComponent: React.FC<SensitiveFieldControlProps> = ({
  anonymizedHash,
  anonymizedMenuItem = 'input',
  children,
  fieldName,
  isProposal,
  languageCode,
  realValue,
  saveCallback,
  sensitivity,
}) => {
  const [displaySensitivityModal, setDisplaySensitivityModal] = useState(false);

  const onSensitiveControlClick = () => {
    setDisplaySensitivityModal(!displaySensitivityModal);
  };

  const sensitiveControlText = (() => {
    if (displaySensitivityModal && isProposal && sensitivity) {
      return realValue;
    }
    if (sensitivity === 'anonymized') {
      return (
        HashUtil.value_or_placeholder_text(
          anonymizedHash || {},
          languageCode,
          I18n.t('missing_translation') + ': ',
        ) ?? I18n.t('missing_translation')
      );
    }
    if (sensitivity === 'confidential' && isProposal) {
      return I18n.t('confidential_sensitivity_label');
    }
  })();

  const saveSensitiveCallback = (value: {
    sensitivity: Sensitivity;
    anonymized?: LocString;
  }) => {
    saveCallback &&
      saveCallback({
        [fieldName ? `${fieldName}_anonymized` : 'anonymized']: value.anonymized
          ? {
              ...anonymizedHash,
              ...value.anonymized,
            }
          : {},
        [fieldName ? `${fieldName}_sensitivity` : 'sensitivity']:
          value.sensitivity,
      });
    setDisplaySensitivityModal(false);
  };

  return (
    <SensitiveControl
      anonymizedHash={anonymizedHash}
      displaySensitivityModal={displaySensitivityModal}
      languageCode={languageCode}
      onClick={onSensitiveControlClick}
      saveCallback={saveSensitiveCallback}
      sensitiveControlText={sensitiveControlText}
      sensitivity={sensitivity}
      anonymizedMenuItem={anonymizedMenuItem}
    >
      {sensitivity == null || !isProposal ? children : null}
    </SensitiveControl>
  );
};

const ObservedSensitiveFieldControl = observer(SensitiveFieldControlComponent);

const SensitiveFieldControlWrapper = (props: SensitiveFieldControlProps) => {
  const sensitiveFieldsEnabled = useSensitiveFieldsEnabled();
  if (!sensitiveFieldsEnabled || !props.isSensitive) {
    return props.children;
  }
  return <ObservedSensitiveFieldControl {...props} />;
};

export const SensitiveFieldControl = observer(SensitiveFieldControlWrapper);
