import _ from "underscore";
import type { LocString } from "schema/schema";

export class HashUtil {
  static value_or_translation(
    hash: LocString,
    languageCode: string | undefined,
  ): string | null | undefined {
    if (!hash) {
      return;
    }
    if (!languageCode) {
      return;
    }

    return hash[languageCode] || Object.values(hash).filter(Boolean)[0];
  }

  static value_or_placeholder_text(
    non_cleaned_hash: LocString | string | undefined,
    selected_language_code?: string,
    extra_label?: string,
  ): string | undefined {
    if (!selected_language_code) {
      return;
    }
    if (non_cleaned_hash === undefined || _.isEmpty(non_cleaned_hash)) {
      return;
    }
    if (_.compact(_.values(non_cleaned_hash)).length < 1) {
      if (_.isObject(non_cleaned_hash)) {
        return;
      }
      return non_cleaned_hash as string;
    }
    const hash = {};
    Object.keys(non_cleaned_hash).forEach((key) => {
      if (non_cleaned_hash[key] !== "") {
        hash[key] = non_cleaned_hash[key];
      }
    });
    if (hash[selected_language_code]) {
      return hash[selected_language_code];
    }

    return `${extra_label ? extra_label + " " : ""}${_.first(
      _.values(hash),
    )} (${_.first(_.keys(hash))})`;
  }

  static is_not_empty(hash: LocString): boolean {
    return Object.values(hash || {}).filter(Boolean).length > 0;
  }
}
